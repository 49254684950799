// @flow

import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
// import { combineEpics, createEpicMiddleware } from 'redux-observable'
// import { firebaseReducer } from 'react-redux-firebase'
// import { firestoreReducer } from 'redux-firestore'
// import LogRocket from 'logrocket'

// import firebase from './firebase'
// import catalogDuck from './catalogDuck'
// import catalogEpic from './catalogEpic'
// import datetimeDuck from './datetimeDuck'
// import downloadDuck from './downloadDuck'
// import downloadEpic from './downloadEpic'
// import hoverDuck from './hoverDuck'
// import legendDuck from './legendDuck'
import mapDuck from './mapDuck'
// import mapEpic from './mapEpic'
// import paintDuck from './paintDuck'

// const firestore = firebase.firestore()
// const rootEpic = (...args) =>
//   combineEpics(catalogEpic, downloadEpic, mapEpic)(...args, firestore)

// const epicMiddleware = createEpicMiddleware()

const reducer = combineReducers({
  // catalog: catalogDuck.reducer,
  // datetime: datetimeDuck.reducer,
  // download: downloadDuck.reducer,
  // firebase: firebaseReducer,
  // firestore: firestoreReducer,
  // hover: hoverDuck.reducer,
  // legend: legendDuck.reducer,
  map: mapDuck.reducer
  // paint: paintDuck.reducer
})

const store = configureStore({
  reducer: reducer,
  middleware: [],
  devTools: true
})

// epicMiddleware.run(rootEpic)

// window.firestore = firestore
window.store = store

export default store
