// Elasticsearch
export const es_url = process.env.REACT_APP_ES_URL
export const es_key = process.env.REACT_APP_ES_KEY
export const es_index = process.env.REACT_APP_ES_INDEX
export const es_type = process.env.REACT_APP_ES_TYPE

// Mapbox
export const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

// Sentry
export const sentryDsn = process.env.REACT_APP_SENTRY_DSN

// Default map values
export const defaultViewport = {
  latitude: 53.28,
  longitude: -60.43,
  zoom: 1,
}
