const theme = {
    typography: {
        fontFamily: 'Raleway, Helvetica, sans-serif',
    },
    colors: {
        primaryColor: '#008000',
        titleColor: 'white'
    },
    secondaryColor: 'mediumseagreen',
};

export default theme;
