import React from 'react'
import PropTypes from 'prop-types'

const SearchFilters = ({ currentTopics, setTopics, visible }) => (
  <div className={`flex column filters-container ${!visible ? 'hidden' : ''}`}>
    <div className='child m10'></div>
  </div>
)

SearchFilters.propTypes = {
  currentTopics: PropTypes.arrayOf(PropTypes.string),
  setTopics: PropTypes.func,
  visible: PropTypes.bool
}

export default SearchFilters
