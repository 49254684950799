import React, { useState } from 'react'
import { ReactiveBase, DataSearch } from '@appbaseio/reactivesearch'
import { Provider } from 'react-redux'

import defaultStore from './redux/store'
import Header from './components/Header'
import Results from './components/Results'
import ReactiveMapContainer from './containers/ReactiveMapContainer'
import { es_url, es_key, es_index, es_type } from './config'
import theme from './theme'
import './App.css'


function App (props) {
  const [currentTopics, setTopics] = useState([])

  const toggleTopic = (topic) => {
    const nextState = currentTopics.includes(topic)
          ? currentTopics.filter(item => item !== topic)
          : currentTopics.concat(topic);
    setTopics(nextState)
  }

  return (
    <Provider store={defaultStore}>
      <section className="container">
        <ReactiveBase
          url={es_url}
          app={es_index}
          credentials={es_key}
          type={es_type}
          theme={theme}
          enableAppbase={true}
        >
          <div className="flex row-reverse app-container">
            <Header currentTopics={currentTopics} setTopics={setTopics} />
            <div className="results-container">
              <DataSearch
                componentId="repo"
                filterLabel="Search"
                dataField={[
                  'properties.title',
                  'properties.description',
                  'properties.title.raw',
                  'properties.keywords'
                ]}
                // dataField='properties.title'
                placeholder="Search STAC"
                iconPosition="left"
                autosuggest={true}
                debounce={300}
                URLParams
                className="data-search-container results-container"
                innerClass={{
                  input: 'search-input',
                }}
              />
              <ReactiveMapContainer />
              <Results currentTopics={currentTopics} toggleTopic={toggleTopic} />
            </div>
          </div>
        </ReactiveBase>
      </section>
    </Provider>
  )
}

export default App
