// import {number} from "@storybook/addon-knobs";

export const basemapStyle = 'mapbox://styles/gnoseworthy/ck37pn6rv7o0a1cmm2l4h5xsi'

export const staticSegmentUrl =
  'https://river-ice.s3.ca-central-1.amazonaws.com/churchill/STATIC/CHURCHILL_SEGMENTS.geojson'
export const staticPlaceUrl =
  'https://river-ice.s3.ca-central-1.amazonaws.com/churchill/STATIC/CHURCHILL_PLACES.geojson'
export const staticStationUrl =
  'https://river-ice.s3.ca-central-1.amazonaws.com/churchill/STATIC/CHURCHILL_STATIONS.geojson'

export const staticSegmentLabelUrl =
  'https://river-ice.s3.ca-central-1.amazonaws.com/churchill/STATIC/CHURCHILL_SEGMENT_LABELS.geojson'
export const segmentStyle = { 'line-color': 'light green', 'line-width': 2 }
export const placeLabelPaint = {
  'text-color': 'black',
  'text-halo-width': 2,
  'text-halo-color': 'white'
}
export const stationLabelPaint = {
  'text-color': 'red',
  'text-halo-width': 1,
  'text-halo-color': 'white'
}
export const pointStyle = {}

export const jsonLine = {
  type: 'line',
  source: 'data-line'
}

export const jsonPlace = {
  type: 'symbol',
  source: 'data-place'
}

export const jsonSegmentLayer = {
  type: 'symbol',
  source: 'data-segment-labels'
}

export const jsonStation = { type: 'circle', source: 'data-stations' }

export
  const circleStyle = {
    // 'circle-radius': number('Point Radius', 4),
    'circle-radius': 4,
    'circle-color': '#dd8628'
  }

export const stationLabelLayout = {
          'text-font': ['Open Sans Regular'],
          'text-field': ['get', 'ADRS'],
          'text-size': 12,
          'text-anchor': 'right',
          'text-offset': [-1, 0]
        }

export const segmentLabelLayout = {
          'text-font': ['Open Sans Regular'],
          'text-field': ['get', 'Label'],
          'text-allow-overlap': true,
          'text-size':  24
        }

export const placeLabelLayout = {
          'text-font': ['Open Sans Regular'],
          'text-field': ['get', 'Label'],
          'text-size': 12,
          'text-max-width': 32
        }
